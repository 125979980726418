import Vue from 'vue';

import {
  Table,
  TableColumn,
  Tooltip,
} from 'element-ui';

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate';

import Sidebar from './UI/Sidebar.vue';
import Navbar from './UI/Navbar/Navbar.vue';
import NavbarToggler from './UI/Navbar/NavbarToggler.vue';
import Input from './UI/Inputs/Input.vue';
import Checkbox from './UI/Inputs/Checkbox.vue';
import Radio from './UI/Inputs/Radio.vue';
import Switcher from './UI/Inputs/Switcher.vue';
import Select from './UI/Inputs/Select.vue';
import PasswordGenerator from './UI/PasswordGenerator.vue';
import Phone from './UI/Phone';
import Button from './UI/Button.vue';
import NewButton from './UI/NewButton.vue';
import Card from './UI/Cards/Card.vue';
import StatsCard from './UI/Cards/StatsCard.vue';
import Dropdown from './UI/Dropdown.vue';
import Icon from './UI/Icon.vue';
import IconButton from './UI/IconButton.vue';
import Stars from './UI/Stars.vue';
import Loading from './UI/Loading.vue';
import Modal from './UI/Modal.vue';
import Pagination from './UI/Pagination.vue';
import Avatar from './UI/Avatar.vue';
import InfoSection from './UI/InfoSection.vue';
import LineChart from './UI/Charts/LineChart';
import DatePicker from './UI/DatePicker.vue';
import Calendar from './UI/Calendar.vue';
import CalendarPicker from './UI/CalendarPicker.vue';
import CalendarRange from './UI/CalendarRange.vue';
import ModalAlert from './UI/ModalAlert.vue';
import DataTable from './UI/DataTable.vue';
import Badge from './UI/Badge.vue';
import Tabs from './UI/Tabs/Tabs.vue';
import Alert from './UI/Alert.vue';
import DateBirth from './UI/Inputs/DateBirth.vue';
import DatePeriod from './UI/DatePeriod.vue';
import ActionDown from './UI/ActionDown.vue';
import Password from './UI/Password.vue';
import ModalStatus from './UI/ModalStatus.vue';
import TextCopy from './UI/TextCopy.vue';
import Popover from './UI/Popover.vue';
import Tag from './UI/Tag.vue';
import Upload from './UI/Upload.vue';
import CalendarPeriod from './UI/CalendarPeriod.vue';
import OtpInput from './UI/Inputs/Otp/OtpInput.vue';
import Emoji from './UI/Emoji.vue';
import Toggle from './UI/Toggle.vue';
import IconUpload from './UI/IconUpload.vue';
import ButtonUpload from './UI/ButtonUpload.vue';
import ListInfo from './UI/ListInfo.vue';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.component('ui-sidebar', Sidebar);
Vue.component('ui-navbar', Navbar);
Vue.component('ui-navbar-toggler', NavbarToggler);
Vue.component('ui-sidebar', Sidebar);
Vue.component('ui-input', Input);
Vue.component('ui-checkbox', Checkbox);
Vue.component('ui-radio', Radio);
Vue.component('ui-switcher', Switcher);
Vue.component('ui-select', Select);
Vue.component('ui-password', PasswordGenerator);
Vue.component('ui-phone', Phone);

// Replace and kill this button after redesign
Vue.component('ui-button', Button);
Vue.component('new-button', NewButton);

Vue.component('ui-card', Card);
Vue.component('ui-stats-card', StatsCard);
Vue.component('ui-dropdown', Dropdown);
Vue.component('ui-icon', Icon);
Vue.component('ui-icon-button', IconButton);
Vue.component('ui-stars', Stars);
Vue.component('ui-loading', Loading);
Vue.component('ui-modal', Modal);
Vue.component('ui-pagination', Pagination);
Vue.component('ui-avatar', Avatar);
Vue.component('ui-list-info', ListInfo);
Vue.component('ui-info-section', InfoSection);
Vue.component('ui-line-chart', LineChart);
Vue.component('ui-text-copy', TextCopy);
Vue.component('ui-tag', Tag);
Vue.component('ui-toggle', Toggle);

Vue.component('ui-table', Table);
Vue.component('ui-table-column', TableColumn);
Vue.component('ui-tooltip', Tooltip);
Vue.component('ui-popover', Popover);
Vue.component('ui-date-picker', DatePicker);
Vue.component('ui-calendar', Calendar);
Vue.component('ui-calendar-picker', CalendarPicker);
Vue.component('ui-calendar-range', CalendarRange);
Vue.component('ui-modal-alert', ModalAlert);
Vue.component('ui-data-table', DataTable);
Vue.component('ui-badge', Badge);
Vue.component('ui-tabs', Tabs);
Vue.component('ui-alert', Alert);
Vue.component('ui-date-birth', DateBirth);
Vue.component('ui-date-period', DatePeriod);
Vue.component('ui-action-down', ActionDown);
Vue.component('ui-password', Password);
Vue.component('ui-modal-status', ModalStatus);
Vue.component('ui-upload', Upload);
Vue.component('ui-calendar-period', CalendarPeriod);
Vue.component('ui-otp-input', OtpInput);
Vue.component('ui-emoji', Emoji);
Vue.component('ui-icon-upload', IconUpload);
Vue.component('ui-button-upload', ButtonUpload);
