<template>
  <ui-modal
    v-if="locations.length && defaultLocationRequired"
    show
    class="user-locations-modal"
    :closable="false"
    no-footer
  >
    <template #header>
      <span class="user-locations-modal__title">
        Choose Location
      </span>
    </template>
    <ul class="user-locations-modal__list">
      <li
        v-for="item in locations"
        :key="item.id"
        class="user-locations-modal__item"
        @click.prevent="handleSetLocation(item)"
      >
        <ui-avatar
          :image="item.image"
          :size="28"
          borderless
          readonly
        />
        <span class="user-locations-modal__name">
          {{ item.name }}
        </span>
      </li>
    </ul>
  </ui-modal>

  <ui-modal-alert
    v-else-if="defaultLocationRequired"
    show
    :closable="false"
    @apply="closeWarning"
    no-close
  >
    <h4>{{ $t('location.text') }}</h4>
  </ui-modal-alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import NProgress from 'nprogress';

export default {
  computed: {
    ...mapState('user', [
      'location',
      'locations',
    ]),
    ...mapGetters('user', [
      'isDefaultLocationExist',
      'defaultLocationRequired',
    ]),
    selectedLocation() {
      return this.locations.find((item) => Number(item.id) === Number(this.location.id));
    },
  },

  methods: {
    ...mapActions([
      'resetStore',
    ]),
    ...mapActions({
      setLocation: 'user/setLocation',
      logout: 'auth/logout',
    }),
    isSelectedLocation(item) {
      return item.id === this.selectedLocation?.id;
    },
    async handleSetLocation(item) {
      if (this.isSelectedLocation(item) && this.isDefaultLocationExist) {
        return;
      }

      try {
        NProgress.start();
        this.resetStore('location-change');
        await this.setLocation({ locationId: item.id });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        NProgress.done();
      }
    },
    async closeWarning() {
      try {
        await this.logout();
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>
