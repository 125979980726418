<template>
  <div v-if="locations.length">
    <div class="user-locations-dropdown">
      <ui-avatar
        class="user-locations-dropdown__current-image"
        :image="selectedLocationImage"
        :size="40"
        borderless
        readonly
      />
      <span class="user-locations-dropdown__title" :title="selectedLocationName">
        {{ selectedLocationName }}
      </span>
      <ui-dropdown
        v-if="locations.length > 1"
        direction="bottom"
        position="left"
        append-to-body
      >
        <template #control>
          <ui-icon-button
            class="user-locations-dropdown__icon"
            name="arrow-down"
          />
        </template>
        <ul class="user-locations-dropdown__list">
          <li
            v-for="item in locations"
            :key="item.id"
            class="user-locations-dropdown__item"
            :class="{ 'user-locations-dropdown__item-active': isSelectedLocation(item) }"
            @click.prevent="handleSetLocation(item)"
          >
            <ui-avatar
              class="user-locations-dropdown__image"
              :image="item.image"
              :size="28"
              borderless
              readonly
            />
            <span class="user-locations-dropdown__subtitle">
              {{ item.name }}
            </span>
          </li>
        </ul>
      </ui-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import NProgress from 'nprogress';

export default {
  computed: {
    ...mapState('user', [
      'location',
      'locations',
    ]),
    ...mapGetters('user', [
      'isDefaultLocationExist',
    ]),
    selectedLocation() {
      return this.locations.find((item) => Number(item.id) === Number(this.location.id));
    },
    selectedLocationName() {
      return this.selectedLocation?.name || 'Location';
    },
    selectedLocationImage() {
      return this.selectedLocation?.image;
    },
  },

  methods: {
    ...mapActions([
      'resetStore',
    ]),
    ...mapActions({
      setLocation: 'user/setLocation',
    }),
    isSelectedLocation(item) {
      return item.id === this.selectedLocation?.id;
    },
    async handleSetLocation(item) {
      if (this.isSelectedLocation(item) && this.isDefaultLocationExist) {
        return;
      }

      try {
        NProgress.start();
        this.resetStore('location-change');
        await this.setLocation({ locationId: item.id });
      } catch (e) {
        this.$showServerError(e);
      } finally {
        NProgress.done();
      }
    },
  },
};
</script>
