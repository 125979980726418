<template>
  <div class="w-100">
    <template v-if="isOnboarding">
      <new-button
        v-if="isVenue"
        :is-loading="loading.venue"
        wide
        @click="goStripe('venue')"
      >
        Add company Stripe account
      </new-button>
      <new-button
        v-else
        :is-loading="loading.individual"
        wide
        @click="goStripe('individual')"
      >
        Add Stripe account
      </new-button>
    </template>
    <template v-else>
      <ui-button
        v-if="showManagePayout"
        v-protected
        action
        type="primary"
        :disabled="!isProfileFilled || loading.individual || !stripeOnBoarding.individual"
        :is-loading="loading.individual"
        class="mt-3 mr-4"
        @click="goStripe('individual')"
      >
        {{ 'Individual Stripe account' }}
      </ui-button>
      <ui-button
        v-if="showManageVenuePayout"
        v-protected
        action
        type="primary"
        :disabled="!isProfileFilled || loading.venue || !stripeOnBoarding.venue"
        :is-loading="loading.venue"
        class="mt-3"
        @click="goStripe('venue')"
      >
        {{ 'Venue stripe account' }}
      </ui-button>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/api';

export default {

  props: {
    backRoute: {
      type: String,
      default: '',
    },
    isOnboarding: Boolean,
    isVenue: Boolean,
  },

  data: () => ({
    loading: {
      individual: false,
      venue: false,
    },
    stripeOnBoarding: {
      individual: null,
      venue: null,
    },
  }),

  computed: {
    ...mapState('user', [
      'profile',
      'location',
      'showManagePayout',
      'showManageVenuePayout',
    ]),
    ...mapGetters('user', [
      'isProfileFilled',
    ]),
    locationId() {
      return this.location.id;
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      return Promise.all([
        this.showManagePayout && this.getStripeAccount('individual'),
        this.showManageVenuePayout && this.getStripeAccount('venue'),
      ]);
    },
    async getStripeAccount(key) {
      this.loading[key] = true;

      try {
        const { data } = await api.stripe[key].getAccount(this.locationId);

        this.stripeOnBoarding[key] = data;
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading[key] = false;
      }
    },
    getStripeLink(key) {
      const url = this.isOnboarding
        ? `${window.location.origin}/${this.backRoute || 'onboarding-completed'}`
        : window.location.href;

      const request = {
        refreshUrl: url,
        returnUrl: url,
      };

      if (key === 'venue') {
        request.locationId = this.locationId;
      }

      if (this.stripeOnBoarding[key]?.payments) {
        return api.stripe[key].updateAccount(request);
      }

      return api.stripe[key].createAccount(request);
    },
    async goStripe(key) {
      if (this.loading[key]) {
        return;
      }

      this.loading[key] = true;

      try {
        const { data } = await this.getStripeLink(key);

        if (data) {
          window.location.href = data;
        }
      } catch (e) {
        this.$showServerError(e);
      } finally {
        this.loading[key] = false;
      }
    },
  },
};
</script>
