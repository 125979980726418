<template>
  <div class="user-api-switch">
    <span class="user-api-switch__title">
      {{ selected ? selected.label : '' }}
    </span>

    <ui-dropdown
      class="user-api-switch__menu"
      direction="bottom"
      position="left"
      append-to-body
    >
      <template #control>
        <ui-icon-button
          class="user-api-switch__icon"
          name="arrow-down"
        />
      </template>

      <ul class="user-api-switch__list">
        <li
          v-for="item in items"
          :key="item.name"
          class="user-api-switch__list-item"
          :class="{ 'selected': item.isSelected }"
          @click="update(item)"
        >
          <ui-icon
            class="user-api-switch__list-item-icon"
            :name="item.icon"
          />

          <span class="user-api-switch__list-item-title">
            {{ item.label }}
          </span>
        </li>
      </ul>
    </ui-dropdown>
  </div>
</template>

<script>
export default {
  name: 'UserApiSwitch',

  data: () => ({
    selected: '',
    items: [],
  }),

  created() {
    this.selected = this.$apiSwitch.selected;
    this.items = this.$apiSwitch.items;
  },

  methods: {
    update(item) {
      this.$apiSwitch.selected = item;

      if (this.$route.name === 'location-view') {
        window.location = this.$router.resolve({ name: 'locations' })?.href;
      } else {
        [window.location] = window.location.href.split('?');
      }
    },
  },
};
</script>
