<template>
  <div id="onboarding-onfido-ref"></div>
</template>

<script>
import { init } from 'onfido-sdk-ui';

export default {
  name: 'UserOnfido',

  props: {
    sdkToken: {
      type: String,
      required: true,
    },
  },

  methods: {
    complete() {
      this.$emit('complete');
    },
  },

  mounted() {
    init({
      language: 'en_GB',
      token: this.sdkToken,
      containerId: 'onboarding-onfido-ref',
      onComplete: this.complete,
      steps: [
        'welcome',
        {
          type: 'document',
          options: {
            documentTypes: {
              national_identity_card: {
                country: 'ARE',
              },
            },
            hideCountrySelection: true,
          },
        },
        {
          type: 'face',
          options: {
            requestedVariant: 'video',
            useMultipleSelfieCapture: false,
          },
        },
        'complete',
      ],
      customUI: {
        fontFamilyTitle: 'Roboto',
        colorBackgroundSurfaceModal: '#F4F4F4',
        colorBorderSurfaceModal: 'none',
        borderWidthSurfaceModal: 0,
        borderRadiusSurfaceModal: 0,
      },
    });
  },
};
</script>
